import { useMemo } from "react";

import { GET_ADMIN_BID_APPLY_ITEMS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import {
  ADMIN_BID_APPLY_AIR_BASIC_FARE,
  ADMIN_BID_APPLY_CONSOLIDATION_BASiC_FARE,
  ADMIN_BID_APPLY_FCL_BASIC_FARE,
  ADMIN_BID_APPLY_LCL_BASIC_FARE,
  ADMIN_BID_APPLY_OCEANTICKET_BASIC_FARE,
} from "@sellernote/_shared/src/constants/forwarding/adminBid";
import {
  Currency,
  FreightType,
  ItemUnitMeasurement,
} from "@sellernote/_shared/src/types/common/common";
import {
  AdminBidDetail,
  ShipmentItem,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  AdminBidApplyCostType,
  AdminBidApplyFare,
  AdminBidApplyItem,
  ApplyBidFeeData,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { BidServiceType } from "@sellernote/_shared/src/types/forwarding/bid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";
import { getCustomRound } from "@sellernote/_shared/src/utils/common/number";
import { getContainerTypeAndAmountArr } from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";
import { findExchangeRate } from "@sellernote/_shared/src/utils/forwarding/admin/tradingStatement";

export default function useDefaultDataForApplyBidForm({
  bidDetail,
  applyItems,
  exchangeRateList,
}: {
  bidDetail?: AdminBidDetail;
  applyItems?: GET_ADMIN_BID_APPLY_ITEMS_RES;
  exchangeRateList?: ExchangeRate[];
}) {
  const bidApplyItem = useMemo(() => {
    return applyItems
      ?.filter((v: AdminBidApplyItem) => {
        if (bidDetail?.locale === "KR") {
          return v.language === "KR";
        }

        return v.language === "EN";
      })
      .map((v: AdminBidApplyItem) => {
        /**
         * 항공 견적의 경우 국내비용 중 THC 비용 VAT 체크를 강제
         * 견적 기본 항목에 대해서 서버에서는 따로 화물 타입별로 관리하고 있지않아서 별도 처리
         * BE에서 화물타입별로 관리할 때, 변경한다고 함 (형주님)
         */
        const isTHC = v.key === "thc" && bidDetail?.freightType === "AIR";

        const defaultItems = {
          key: v.key,
          item: v.name,
          unitPrice: 0,
          currency: v.currency,
          itemPrice: 0,
          amount: 0,
          note: !v.note ? "" : v.note,
          atCost: v.atCost,
          itemUnitMeasurement: v.itemUnitMeasurement,
          isVAT: isTHC ? true : v.isVAT,
          category: v.category,
          // 견적서에서 isTaxExempt는 실제로 사용하지 않고 false 고정 마이그레이션이 늦게 진행될 것 같아서 미리 false를 준다.
          isTaxExempt: v.isTaxExempt ?? false,
        };
        return defaultItems;
      });
  }, [applyItems, bidDetail?.locale, bidDetail?.freightType]);

  const getFclItemUnitMeasurement = (
    itemUnitMeasurement: ItemUnitMeasurement | undefined,
    key: string
  ): ItemUnitMeasurement => {
    if (
      !itemUnitMeasurement ||
      itemUnitMeasurement === "UNIT" ||
      itemUnitMeasurement === "R.TON" ||
      itemUnitMeasurement === "3R.TON"
    ) {
      return "CNTR";
    }

    if (key === "tc") return "TRUCK";

    return itemUnitMeasurement;
  };

  const getAirItemUnitMeasurement = (
    itemUnitMeasurement: ItemUnitMeasurement | undefined
  ): ItemUnitMeasurement => {
    if (
      !itemUnitMeasurement ||
      itemUnitMeasurement === "UNIT" ||
      itemUnitMeasurement === "R.TON" ||
      itemUnitMeasurement === "CNTR"
    ) {
      return "C/W";
    }
    return itemUnitMeasurement;
  };

  const getLclItemUnitMeasurement = (
    itemUnitMeasurement: ItemUnitMeasurement | undefined,
    serviceType: BidServiceType | undefined
  ): ItemUnitMeasurement => {
    if (
      !itemUnitMeasurement ||
      itemUnitMeasurement === "UNIT" ||
      itemUnitMeasurement === "CNTR"
    ) {
      return serviceType === "oceanTicket" ? "0.1 R.TON" : "R.TON";
    }
    return itemUnitMeasurement;
  };

  const getItemUnitMeasurement = ({
    freightType,
    itemUnitMeasurement,
    key,
  }: {
    freightType?: FreightType;
    itemUnitMeasurement?: ItemUnitMeasurement;
    key: string;
  }) => {
    switch (freightType) {
      case "AIR":
        return getAirItemUnitMeasurement(itemUnitMeasurement);
      case "LCL":
        return getLclItemUnitMeasurement(
          itemUnitMeasurement,
          bidDetail?.serviceType
        );
      default:
        return getFclItemUnitMeasurement(itemUnitMeasurement, key);
    }
  };

  const getItemPrice = ({
    amount,
    unitPrice,
    currency,
  }: {
    amount: number;
    unitPrice: number;
    currency: Currency;
  }) => {
    const itemPrice =
      amount * unitPrice * findExchangeRate(exchangeRateList, currency);

    // 부동소숫점으로 인한 문제가 있어 getCustomRound를 사용
    return getCustomRound(itemPrice, 0);
  };

  //TODO: 실시간 견적 불러오기 API 반영 후 들어오는 key가 정해지면 key도 타입 정리
  const getNote = (key: string, note: string) => {
    if (key === "tc") {
      if (bidDetail?.inlandType === "parcel") {
        return "화물 택배";
      }
      if (bidDetail?.inlandType === "consol") {
        return "합차";
      }
      if (bidDetail?.inlandType === "sole") {
        return "독차";
      }
    }

    if (key === "oceanPerFee") {
      return "BAF&CAF&CRS 포함";
    }

    if (key === "basicRate") {
      return "THC, WFG, DOC FEE,<br>창고보관료(1주일 무료) 등 포함";
    }
    return note;
  };

  const getFeeDataAmount = ({
    amount,
    itemUnitMeasurement,
    shipmentItemList,
  }: {
    amount: number;
    itemUnitMeasurement: ItemUnitMeasurement;
    shipmentItemList: ShipmentItem[];
  }) => {
    // 서로 다른 타입에 컨테이너마다 개별 수량을 넣어주기 위해 컨테이너 타입이 다른지 확인
    const isMultipleContainerType =
      new Set(shipmentItemList?.map((container) => container.containerType))
        .size > 1;

    if (
      bidDetail?.serviceType === "consolidation" &&
      itemUnitMeasurement === "R.TON" &&
      bidDetail?.wareHouse.cbm === 250 &&
      amount * 4 > bidDetail?.totalCBM &&
      amount * 4 > 1
    ) {
      return bidDetail?.totalWeight * 4;
    }
    if (itemUnitMeasurement === "B/L" || itemUnitMeasurement === "TRUCK") {
      return 1;
    }

    if (itemUnitMeasurement === "3R.TON") {
      return amount / 3;
    }

    if (itemUnitMeasurement === "0.1 R.TON") {
      return Math.ceil(amount * 10);
    }

    if (
      itemUnitMeasurement === "CNTR" &&
      Boolean(shipmentItemList) &&
      !isMultipleContainerType
    ) {
      return shipmentItemList.reduce((acc, { quantity }) => acc + quantity, 0);
    }

    return amount < 1 ? 1 : amount;
  };

  const getUnitPrice = (key: string, price: number) => {
    if (bidDetail?.serviceType === "consolidation" && key === "oceanFreight") {
      return bidDetail?.wareHouse.moveFee;
    }

    return price;
  };

  const getCurrency = (
    feeDataCategory: AdminBidApplyCostType,
    currency: Currency
  ) => {
    if (
      bidDetail?.serviceType === "consolidation" &&
      bidDetail?.wareHouse.name === "이우" &&
      (feeDataCategory === "freightCost" || feeDataCategory === "localCost")
    ) {
      return "KRW";
    }

    return currency;
  };

  const getFeeDataForForm = ({
    feeItem,
    price,
    amount,
    feeDataCategory,
    shipmentItemList,
  }: {
    feeItem: ApplyBidFeeData;
    price: number;
    amount: number;
    feeDataCategory: AdminBidApplyCostType;
    shipmentItemList: ShipmentItem[];
  }) => {
    const feeItemItemUnitMeasurement = getItemUnitMeasurement({
      freightType: bidDetail?.freightType,
      itemUnitMeasurement: feeItem.itemUnitMeasurement,
      key: feeItem.key,
    });

    const feeItemAmount = getFeeDataAmount({
      amount,
      itemUnitMeasurement: feeItemItemUnitMeasurement,
      shipmentItemList,
    });

    // 오션티켓과 특송인 경우 창고 moveFee를 적용해야함
    const unitPrice = getUnitPrice(feeItem.key, price);

    return {
      ...feeItem,
      currency: getCurrency(feeDataCategory, feeItem.currency),
      unitPrice,
      amount: feeItemAmount,
      itemPrice: getItemPrice({
        amount: feeItemAmount,
        unitPrice,
        currency: feeItem.currency,
      }),
      itemUnitMeasurement: feeItemItemUnitMeasurement,
      note: getNote(feeItem.key, feeItem.note),
    };
  };

  const getFilterFreightFeeData = (
    acc: ApplyBidFeeData[],
    cur: ApplyBidFeeData
  ) => {
    if (
      (bidDetail?.incoterms === "CIF" || bidDetail?.incoterms === "CFR") &&
      (cur.key === "oceanFreight" || cur.key === "oceanPerFee")
    ) {
      acc = [...acc];
    } else if (
      (bidDetail?.incoterms === "CIF" || bidDetail?.incoterms === "CFR") &&
      cur.key === "oceanPerFee"
    ) {
      acc = [...acc];
    } else if (!bidDetail?.containLss && cur.key === "lss") {
      acc = [...acc];
    } else if (!bidDetail?.containOceanSurcharge && cur.key === "bafCaf") {
      acc = [...acc];
    } else if (
      !bidDetail?.containOceanSurcharge &&
      cur.key === "totalAdditionalFee"
    ) {
      acc = [...acc];
    } else {
      acc = [...acc, cur];
    }

    return acc;
  };

  const getFilterLocalFeeData = (
    acc: ApplyBidFeeData[],
    cur: ApplyBidFeeData
  ) => {
    if (
      !bidDetail?.needFTACertificateAgency &&
      cur.key === "ftaCertificateAgencyFee"
    ) {
      acc = [...acc];
    } else {
      acc = [...acc, cur];
    }
    return acc;
  };

  const getFilterDomesticFeeData = (
    acc: ApplyBidFeeData[],
    cur: ApplyBidFeeData
  ) => {
    if (!bidDetail?.endAddress && cur.key === "cfsCharge") {
      acc = [...acc];
    } else if (!bidDetail?.hopeCargoInsurance && cur.key === "insuranceFee") {
      acc = [...acc];
    } else if (
      !bidDetail?.containCustoms &&
      cur.key === "customsClearanceFee"
    ) {
      acc = [...acc];
    } else if (!bidDetail?.endAddress && cur.key === "shuttleFee") {
      acc = [...acc];
    } else if (bidDetail?.containerAccessable && cur.key === "cfsCharge") {
      acc = [...acc];
    } else if (
      !bidDetail?.containOceanSurcharge &&
      cur.key === "totalAdditionalFee"
    ) {
      acc = [...acc];
    } else if (bidDetail?.containerAccessable && cur.key === "shuttleFee") {
      acc = [...acc];
    } else {
      acc = [...acc, cur];
    }

    return acc;
  };

  const getFilterOtherFeeData = (
    acc: ApplyBidFeeData[],
    cur: ApplyBidFeeData
  ) => {
    if (!bidDetail?.containCustoms && cur.key === "inspectionFee") {
      acc = [...acc];
    } else {
      acc = [...acc, cur];
    }
    return acc;
  };

  const getFilterTaxFeeData = (
    acc: ApplyBidFeeData[],
    cur: ApplyBidFeeData
  ) => {
    if (!bidDetail?.containCustoms && cur.key === "VATFee") {
      acc = [...acc];
    } else if (!bidDetail?.containCustoms && cur.key === "customsFee") {
      acc = [...acc];
    } else {
      acc = [...acc, cur];
    }
    return acc;
  };

  const getFilterFeeDataInland = (
    acc: ApplyBidFeeData[],
    cur: ApplyBidFeeData
  ) => {
    if (!bidDetail?.endAddress && cur.key === "tc") {
      acc = [...acc];
    } else {
      acc = [...acc, cur];
    }
    return acc;
  };

  const getFilterFeeData = (
    feeData: ApplyBidFeeData[],
    feeDataCategory: AdminBidApplyCostType
  ) => {
    const filterFeeData = feeData.reduce((acc, cur) => {
      if (feeDataCategory === "localCost") {
        return getFilterLocalFeeData(acc, cur);
      }

      if (feeDataCategory === "freightCost") {
        return getFilterFreightFeeData(acc, cur);
      }

      if (feeDataCategory === "domesticCost") {
        return getFilterDomesticFeeData(acc, cur);
      }

      if (feeDataCategory === "otherCost") {
        return getFilterOtherFeeData(acc, cur);
      }

      if (feeDataCategory === "inlandCost") {
        return getFilterFeeDataInland(acc, cur);
      }

      if (feeDataCategory === "tax") {
        return getFilterTaxFeeData(acc, cur);
      }

      return acc;
    }, [] as ApplyBidFeeData[]);

    return filterFeeData;
  };

  const getFeeData = ({
    fare,
    amount,
    feeDataCategory,
    shipmentItemList,
  }: {
    fare: AdminBidApplyFare;
    amount: number;
    feeDataCategory: AdminBidApplyCostType;
    shipmentItemList: ShipmentItem[];
  }) => {
    const fareArray = Object.entries(fare);

    const feeData = fareArray.reduce((acc, cur) => {
      const feeItem = bidApplyItem
        ?.filter((defaultItem) => {
          return defaultItem.category === feeDataCategory;
        })
        .find((v) => {
          return v.key === cur[0];
        });

      if (feeItem) {
        acc = [
          ...acc,
          getFeeDataForForm({
            feeItem,
            price: cur[1],
            amount,
            feeDataCategory,
            shipmentItemList,
          }),
        ];
      }

      return acc;
    }, [] as ApplyBidFeeData[]);

    return getFilterFeeData(feeData, feeDataCategory);
  };

  const getMultipleContainerFeeData = (
    fare: AdminBidApplyFare,
    feeDataCategory: AdminBidApplyCostType,
    shipmentItemList: ShipmentItem[]
  ) => {
    const containerTypeAndAmountArr =
      getContainerTypeAndAmountArr(shipmentItemList);

    // itemUnitMeasurement가 CNTR 종류인 feeData
    const cntrFeeData = containerTypeAndAmountArr.reduce((acc, cur) => {
      const feeData = getFeeData({
        fare,
        amount: cur.amount,
        feeDataCategory,
        shipmentItemList,
      });

      const containerTypeFeeData = feeData
        .map((v) => {
          return {
            ...v,
            key:
              v.itemUnitMeasurement === "B/L" ||
              v.itemUnitMeasurement === "TRUCK"
                ? v.key
                : `${v.key}${cur.containersType}`,
            itemUnitMeasurement:
              v.itemUnitMeasurement === "B/L" ||
              v.itemUnitMeasurement === "TRUCK"
                ? v.itemUnitMeasurement
                : (`${v.itemUnitMeasurement}(${cur.containersType})` as ItemUnitMeasurement),
          };
        })
        .filter((v) => {
          return (
            v.itemUnitMeasurement !== "B/L" && v.itemUnitMeasurement !== "TRUCK"
          );
        });

      acc = [...acc, ...containerTypeFeeData];

      return acc;
    }, [] as ApplyBidFeeData[]);

    /**
     * itemUnitMeasurement가 CNTR 종류가 아닌 feeData
     * B/L이나 Truck인 경우엔은 컨테이너가 여러개라도 항목이 여러개가 아니여야 함
     */
    const noCntrFeeData = getFeeData({
      fare,
      amount: 1,
      feeDataCategory,
      shipmentItemList,
    }).filter((v) => {
      return (
        v.itemUnitMeasurement === "B/L" || v.itemUnitMeasurement === "TRUCK"
      );
    });

    return cntrFeeData.concat(noCntrFeeData);
  };

  const getBasicFare = () => {
    if (bidDetail?.serviceType === "oceanTicket") {
      return ADMIN_BID_APPLY_OCEANTICKET_BASIC_FARE;
    }
    if (bidDetail?.serviceType === "consolidation") {
      return ADMIN_BID_APPLY_CONSOLIDATION_BASiC_FARE;
    }
    if (bidDetail?.freightType === "FCL") {
      return ADMIN_BID_APPLY_FCL_BASIC_FARE;
    }
    if (bidDetail?.freightType === "AIR") {
      return ADMIN_BID_APPLY_AIR_BASIC_FARE;
    }
    return ADMIN_BID_APPLY_LCL_BASIC_FARE;
  };

  const getDefaultFeeData = (
    feeDataCategory: AdminBidApplyCostType,
    forwarderFare?: AdminBidApplyFare
  ) => {
    if (
      bidDetail &&
      bidDetail.freightType === "FCL" &&
      bidDetail.bidItems?.length > 1
    ) {
      return getMultipleContainerFeeData(
        getBasicFare(),
        feeDataCategory,
        bidDetail.bidItems
      );
    }

    if (bidDetail?.supply) {
      if (forwarderFare) {
        return getFeeData({
          fare: forwarderFare,
          amount: bidDetail.supply,
          feeDataCategory,
          shipmentItemList: bidDetail.bidItems,
        });
      }
      return getFeeData({
        fare: getBasicFare(),
        amount: bidDetail.supply,
        feeDataCategory,
        shipmentItemList: bidDetail.bidItems,
      });
    }

    return getFeeData({
      fare: getBasicFare(),
      amount: 1,
      feeDataCategory,
      shipmentItemList: bidDetail?.bidItems ?? [],
    });
  };

  return { getDefaultFeeData };
}
