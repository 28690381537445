import Tabs from "@sellernote/_shared/src/admin-ui/components/Tabs";
import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import useSetDebuggingDataForIssueReport from "@sellernote/_shared/src/hooks/admin/setDebuggingDataForIssueReport";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import {
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import BidCreateForm from "./BidCreateForm";

function BidCreate() {
  const [
    sessionStorageShipmentCreateInfo,
    sessionStorageSetShipmentCreateInfo,
  ] = useSessionStorage<BidCreateStorageData>(
    "sessionStorageShipmentCreateInfo"
  );

  const [sessionStorageShipmentCreateUserInfo, _] =
    useSessionStorage<BidCreateUserData | null>(
      "sessionStorageShipmentCreateUserInfo"
    );

  const { data: portList, isFetching } = ADMIN_COMMON_QUERY.useGetPortList();

  const handleSessionStorageShipmentCreateInfoChange = (
    value: BidCreateStorageData
  ) => {
    sessionStorageSetShipmentCreateInfo(value);
  };

  const { setDebuggingDataForIssueReport } =
    useSetDebuggingDataForIssueReport();

  if (sessionStorageShipmentCreateUserInfo) {
    setDebuggingDataForIssueReport({
      userId: sessionStorageShipmentCreateUserInfo.userId.toString(),
      userCompany: sessionStorageShipmentCreateUserInfo.userCompany,
    });
  }

  if (
    !sessionStorageShipmentCreateInfo ||
    !sessionStorageShipmentCreateUserInfo ||
    isFetching
  ) {
    return (
      <Layout title={"의뢰 생성"}>
        <Loading active={true} />
      </Layout>
    );
  }

  return (
    <Layout
      breadcrumbs={[
        sessionStorageShipmentCreateInfo.hasPO ? "발주 관리" : "유저 관리",
      ]}
      title={"의뢰 생성"}
    >
      {portList && !isFetching && (
        <Tabs
          /** 견적서 페이지에서 돌아오거나 앞선 의뢰를 생성한 경우 리랜더링을 위해 key를 추가 */
          key={sessionStorageShipmentCreateInfo.tabsDefaultValue}
          defaultTabPanelValue={
            sessionStorageShipmentCreateInfo.tabsDefaultValue
          }
          tabsData={
            sessionStorageShipmentCreateInfo?.bidCreateData?.map((v, index) => {
              return {
                tabLabel: v.disabled ? `${v.tabLabel}(생성완료)` : v.tabLabel,
                disabled: v.disabled,
                tabPanelItem: (
                  <BidCreateForm
                    portList={portList}
                    bidCreateFormData={v.bidCreateFormData}
                    formIndex={index}
                    hasPO={sessionStorageShipmentCreateInfo.hasPO}
                    POId={sessionStorageShipmentCreateInfo.POId}
                    handleSessionStorageShipmentCreateInfoChange={
                      handleSessionStorageShipmentCreateInfoChange
                    }
                    sessionStorageShipmentCreateInfo={
                      sessionStorageShipmentCreateInfo
                    }
                    sessionStorageShipmentCreateUserInfo={
                      sessionStorageShipmentCreateUserInfo
                    }
                  />
                ),
              };
            }) ?? []
          }
        />
      )}
    </Layout>
  );
}

export default BidCreate;
