import { useParams } from "react-router-dom";
import { ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import Loading from "@sellernote/_shared/src/componentsToMoveToV1/Loading";
import useSessionStorage from "@sellernote/_shared/src/hooks/common/useSessionStorage";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_COMMON_QUERY";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import {
  BidCreateQuotationStorageData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";

import ItemInfoForBidCreate from "pages/bid/_components/ItemInfo/ItemInfoForBidCreate";
import useGetSearchEstimatePayload from "./hooks/useGetSearchEstimatePayload";

import NewQuotation from "./NewQuotation";

function BidCreateQuotation() {
  const [sessionStorageQuotationInfo, _setSessionStorageQuotationInfo] =
    useSessionStorage<BidCreateQuotationStorageData | null>(
      "sessionStorageQuotationInfo"
    );

  const [sessionStorageShipmentCreateUserInfo, _setShipmentCreateUserData] =
    useSessionStorage<BidCreateUserData | null>(
      "sessionStorageShipmentCreateUserInfo"
    );

  const [
    sessionStorageShipmentCreateInfo,
    setSessionStorageShipmentCreateInfo,
  ] = useSessionStorage<BidCreateStorageData | null>(
    "sessionStorageShipmentCreateInfo"
  );

  const handleSessionStorageShipmentCreateInfoChange = (
    value: BidCreateStorageData
  ) => {
    setSessionStorageShipmentCreateInfo(value);
  };

  const { freightType }: { freightType: FreightType } = useParams();

  /** 해당 견적서의 생성 폼 데이터 */
  const bidCreateFormData =
    sessionStorageShipmentCreateInfo?.bidCreateData[
      sessionStorageShipmentCreateInfo.tabsDefaultValue
    ].bidCreateFormData;

  const { data: exchangeRateList } = ADMIN_COMMON_QUERY.useGetExchangeRateList({
    all: true,
  });

  const { data: applyItems } = ADMIN_BID_QUERY.useGetAdminBidApplyItems({
    isImport: bidCreateFormData?.bidType === "import",
    enabled: bidCreateFormData?.bidType !== undefined,
  });

  const { data: linerList } = ADMIN_COMMON_QUERY.useGetLinerList();

  const { data: portList } = ADMIN_COMMON_QUERY.useGetPortList();

  const { isEstimateFetching, estimate } = useGetSearchEstimatePayload({
    sessionStorageQuotationInfo,
    bidCreateFormData,
    freightType,
  });

  const isLoading =
    !bidCreateFormData ||
    !sessionStorageShipmentCreateInfo ||
    !sessionStorageShipmentCreateUserInfo ||
    !sessionStorageQuotationInfo ||
    !exchangeRateList ||
    !applyItems ||
    !linerList ||
    !portList ||
    isEstimateFetching;

  return (
    <Layout title={"견적서 생성"}>
      {isLoading ? (
        <Loading active={true} />
      ) : (
        <Grid container gap={1}>
          <ItemInfoForBidCreate
            bidCreateFormData={bidCreateFormData}
            exchangeRateList={exchangeRateList}
            portList={portList}
          />

          <Grid
            item
            container
            direction="column"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <ErrorOutlineIcon color="error" />
            </Grid>

            <Grid item>
              <Typography variant="subtitle1" color="error">
                {estimate
                  ? "실시간 견적 구간이 존재합니다."
                  : "실시간 견적 구간이 없습니다."}
              </Typography>
            </Grid>
          </Grid>

          <NewQuotation
            bidCreateFormData={bidCreateFormData}
            exchangeRateList={exchangeRateList}
            applyItems={applyItems}
            linerList={linerList}
            estimate={estimate}
            portList={portList}
            isOceanticket={sessionStorageQuotationInfo.isOceanticket}
            sessionStorageQuotationInfo={sessionStorageQuotationInfo}
            sessionStorageShipmentCreateUserInfo={
              sessionStorageShipmentCreateUserInfo
            }
            handleSessionStorageShipmentCreateInfoChange={
              handleSessionStorageShipmentCreateInfoChange
            }
            sessionStorageShipmentCreateInfo={sessionStorageShipmentCreateInfo}
          />
        </Grid>
      )}
    </Layout>
  );
}

export default BidCreateQuotation;
