import { useState } from "react";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";

import { QuotationChangeTypeAfterCargoUpdate } from "@sellernote/_shared/src/admin-ui/pageContainers/bid/detail/types";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import {
  AdminBidDetail,
  ExportShipmentInfoUpdate,
  ImportShipmentInfoUpdate,
} from "@sellernote/_shared/src/types/forwarding/adminBid";

// TODO: 현재 API 배포가 안돼서 성공 실패 로직은 실제 배포 확인 후 추가
export default function useShipmentInfoUpdateRequest({
  requestPayload,
  bidDetail,
  closeModal,
}: {
  requestPayload:
    | ImportShipmentInfoUpdate
    | ExportShipmentInfoUpdate
    | undefined;
  bidDetail: AdminBidDetail;
  closeModal: () => void;
}) {
  const { handleSnackbarOpen } = useSnackbar();

  const history = useHistory();

  const queryClient = useQueryClient();

  const [showsQuotationResetModal, setShowsQuotationResetModal] =
    useState(false);

  const {
    mutate: checkShipmentResetByInfoUpdate,
    ResponseHandler: ResponseHandlerOfCheckShipmentResetByInfoUpdate,
  } = ADMIN_BID_QUERY.useCheckShipmentResetByInfoUpdate();

  const {
    mutate: updateImportShipmentInfoWithoutRecalculation,
    ResponseHandler:
      ResponseHandlerOfUpdateImportShipmentInfoWithoutRecalculation,
  } = ADMIN_BID_QUERY.useImportShipmentInfoUpdateWithoutReCalCulation();

  const {
    mutate: updateExportShipmentInfoWithoutRecalculation,
    ResponseHandler:
      ResponseHandlerOfUpdateExportShipmentInfoWithoutRecalculation,
  } = ADMIN_BID_QUERY.useExportShipmentInfoUpdateWithoutReCalCulation();

  const { id: shipmentId, freightType, isImport, quotationsUser } = bidDetail;

  const handleImportShipmentInfoWithoutRecalculationUpdate = (
    type: QuotationChangeTypeAfterCargoUpdate
  ) => {
    updateImportShipmentInfoWithoutRecalculation(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: () => {
          history.push(
            `/bid/detail/${shipmentId}/quotation/${
              type === "quotationUpdate" ? "fixed" : "new"
            }`
          );
        },

        onError: () => {
          setShowsQuotationResetModal(false);
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleImportRecalculationCheckAndUpdate = async () => {
    const handleExportShipmentItemUpdate = () => {
      updateImportShipmentInfoWithoutRecalculation(
        {
          pathParams: { shipmentId },
          ...requestPayload,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("정보가 수정되었습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: shipmentId,
              })
            );
            closeModal();
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    };

    /** 확정 견적이 없는 경우 체크 여부 없이 강제 수정을 진행 */
    if (quotationsUser.length === 0) {
      handleExportShipmentItemUpdate();
      return;
    }

    checkShipmentResetByInfoUpdate(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: ({ data }) => {
          /** 응답값이 true면 견적 리셋 방법 선택 모달 오픈 */
          if (data) {
            setShowsQuotationResetModal(true);
            return;
          }

          handleExportShipmentItemUpdate();
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleExportShipmentInfoWithoutRecalculationUpdate = (
    type: QuotationChangeTypeAfterCargoUpdate
  ) => {
    updateExportShipmentInfoWithoutRecalculation(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: () => {
          history.push(
            `/bid/detail/${shipmentId}/quotation/${
              type === "quotationUpdate" ? "fixed" : "new"
            }`
          );
        },

        onError: () => {
          setShowsQuotationResetModal(false);
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  const handleExportRecalculationCheckAndUpdate = () => {
    const handleExportShipmentItemUpdate = () => {
      updateExportShipmentInfoWithoutRecalculation(
        {
          pathParams: { shipmentId },
          ...requestPayload,
        },
        {
          onSuccess: () => {
            handleSnackbarOpen("정보가 수정되었습니다.");
            queryClient.invalidateQueries(
              ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                bidId: shipmentId,
              })
            );
            closeModal();
          },

          onError: () => {
            handleSnackbarOpen("요청에 실패했습니다.", "error");
          },
        }
      );
    };

    /** 확정 견적이 없는 경우 체크 여부 없이 강제 수정을 진행 */
    if (quotationsUser.length === 0) {
      handleExportShipmentItemUpdate();
      return;
    }

    checkShipmentResetByInfoUpdate(
      {
        pathParams: { shipmentId },
        ...requestPayload,
      },
      {
        onSuccess: ({ data }) => {
          /** 응답값이 true면 견적 리셋 방법 선택 모달 오픈 */
          if (data) {
            setShowsQuotationResetModal(true);
            return;
          }

          /** 응답값이 false면 견적 리셋없이 바로 수정 */
          updateExportShipmentInfoWithoutRecalculation(
            {
              pathParams: { shipmentId },
              ...requestPayload,
            },
            {
              onSuccess: () => {
                handleSnackbarOpen("요청에 성공했습니다.");
                queryClient.invalidateQueries(
                  ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
                    bidId: shipmentId,
                  })
                );
                closeModal();
              },

              onError: () => {
                handleSnackbarOpen("요청에 실패했습니다.", "error");
              },
            }
          );
        },

        onError: () => {
          handleSnackbarOpen("요청에 실패했습니다.", "error");
        },
      }
    );
  };

  return {
    handleRecalculationCheckAndUpdate: isImport
      ? handleImportRecalculationCheckAndUpdate
      : handleExportRecalculationCheckAndUpdate,
    handleShipmentInfoWithoutRecalculationUpdate: isImport
      ? handleImportShipmentInfoWithoutRecalculationUpdate
      : handleExportShipmentInfoWithoutRecalculationUpdate,
    showsQuotationResetModal,
    setShowsQuotationResetModal,

    ResponseHandler: (
      <>
        {ResponseHandlerOfUpdateImportShipmentInfoWithoutRecalculation}
        {ResponseHandlerOfUpdateExportShipmentInfoWithoutRecalculation}
        {ResponseHandlerOfCheckShipmentResetByInfoUpdate}
      </>
    ),
  };
}
