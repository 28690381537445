import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSetRecoilState } from "recoil";

import { GET_ADMIN_BID_APPLY_ITEMS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { GET_FCL_ESTIMATE_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBidCreate";
import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import {
  FreightType,
  Liner,
  Port,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import {
  BidCreateFormData,
  BidCreateQuotationStorageData,
  BidCreateStorageData,
  BidCreateUserData,
} from "@sellernote/_shared/src/types/forwarding/adminBidCreate";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

import QuotationForm from "pages/bid/_components/QuotationForm";
import useConfirmExportQuotationButton from "./hooks/useConfirmExportQuotationButton";
import useConfirmQuotationButton from "./hooks/useConfirmQuotationButton";
import useReturnToBidCrateButton from "./hooks/useReturnToBidCreateButton";

function NewQuotation({
  bidCreateFormData,
  exchangeRateList,
  applyItems,
  linerList,
  estimate,
  portList,
  isOceanticket,
  sessionStorageQuotationInfo,
  sessionStorageShipmentCreateUserInfo,
  handleSessionStorageShipmentCreateInfoChange,
  sessionStorageShipmentCreateInfo,
}: {
  bidCreateFormData: BidCreateFormData;
  exchangeRateList: ExchangeRate[] | undefined;
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES | undefined;
  linerList: Liner[] | undefined;
  estimate: GET_FCL_ESTIMATE_RES | undefined;
  portList: Port[] | undefined;
  isOceanticket: boolean;
  sessionStorageQuotationInfo: BidCreateQuotationStorageData;
  sessionStorageShipmentCreateUserInfo: BidCreateUserData;
  handleSessionStorageShipmentCreateInfoChange: (
    value: BidCreateStorageData
  ) => void;
  sessionStorageShipmentCreateInfo: BidCreateStorageData;
}) {
  const setCargoInfoFormType = useSetRecoilState(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const { freightType }: { freightType: FreightType } = useParams();

  const methods = useForm<ApplyBidFormData>({
    defaultValues: {
      freightFeeData: estimate?.freightFee ?? [],
      domesticFeeData: estimate?.domesticFee ?? [],
      localFeeData: estimate?.localFee ?? [],
      otherFeeData: estimate?.otherFee ?? [],
      inlandFeeData: estimate?.inlandFee ?? [],
      taxFeeData: estimate?.taxFee ?? [],
      expiredAt: estimate?.fare?.expiredAt
        ? new Date(estimate?.fare?.expiredAt)
        : new Date(),
      isTransit: estimate?.fare?.transitType
        ? estimate?.fare?.transitType === "t/s"
        : false,
      liner: estimate?.fare?.liner,
      freightPaymentType: estimate?.fare?.freightPaymentType
        ? estimate?.fare?.freightPaymentType
        : "cc",
      comment: "",
    },
  });

  const { watch } = methods;

  /**
   * 의뢰상세에서 입력한 물품정보를 "packaging"으로 등록한 한 경우
   * cargoInfoFormType가 디폴트값인 totalVolume으로 변경돼서 다시 변경해줘야함
   */
  const quotationFormRefCallback = useCallback(() => {
    if (
      bidCreateFormData.freightType !== "FCL" &&
      bidCreateFormData.productsInfo
    ) {
      if (bidCreateFormData.productsInfo[0].height) {
        setCargoInfoFormType("packaging");
      }
    }
  }, [
    bidCreateFormData.freightType,
    bidCreateFormData.productsInfo,
    setCargoInfoFormType,
  ]);

  const {
    ConfirmQuotationButton,
    handleQuotationConfirm,
    ResponseHandler: ResponseHandlerOfConfirmQuotation,
  } = useConfirmQuotationButton({
    portList,
    bidCreateFormData,
    isOceanticket,
    freightType: bidCreateFormData.freightType,
    watch,
    linerList,
    exchangeRateList,
    estimate,
    POId: sessionStorageQuotationInfo.POId,
    hasPO: sessionStorageQuotationInfo.hasPO,
    formIndex: sessionStorageQuotationInfo.tabsDefaultValue,
    sessionStorageQuotationInfo: sessionStorageQuotationInfo,
    sessionStorageShipmentCreateUserInfo,
    handleSessionStorageShipmentCreateInfoChange,
    sessionStorageShipmentCreateInfo,
  });

  const {
    ConfirmExportQuotationButton,
    handleExportQuotationConfirm,
    ResponseHandler: ResponseHandlerOfConfirmExportQuotation,
  } = useConfirmExportQuotationButton({
    portList,
    bidCreateFormData,
    freightType: bidCreateFormData.freightType,
    watch,
    linerList,
    exchangeRateList,
    estimate,
    hasPO: sessionStorageQuotationInfo.hasPO,
    formIndex: sessionStorageQuotationInfo.tabsDefaultValue,
    sessionStorageQuotationInfo,
    sessionStorageShipmentCreateUserInfo,
    handleSessionStorageShipmentCreateInfoChange,
    sessionStorageShipmentCreateInfo,
  });

  const { ReturnToBidCreateButton } = useReturnToBidCrateButton();

  return (
    <Grid ref={quotationFormRefCallback}>
      <FormProvider {...methods}>
        <QuotationForm
          freightType={freightType}
          exchangeRateList={exchangeRateList || []}
          applyItems={applyItems || []}
          linerList={linerList || []}
          onSubmit={
            bidCreateFormData.bidType === "import"
              ? handleQuotationConfirm
              : handleExportQuotationConfirm
          }
        >
          {bidCreateFormData.bidType === "import"
            ? ConfirmQuotationButton
            : bidCreateFormData.bidType === "export" &&
              ConfirmExportQuotationButton}

          {ReturnToBidCreateButton}
        </QuotationForm>
      </FormProvider>

      {ResponseHandlerOfConfirmExportQuotation}
      {ResponseHandlerOfConfirmQuotation}
    </Grid>
  );
}

export default NewQuotation;
