import { useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";

function QuotationUserMailModal({
  showsQuotationUserMailModal,
  onQuotationUserMailModalClose,
  onQuotationUserUpdate,
}: {
  showsQuotationUserMailModal: boolean;
  onQuotationUserMailModalClose: () => void;
  onQuotationUserUpdate: (allowsSendmail: boolean) => void;
}) {
  const [allowsSendMail, setAllowsSendMail] = useState(false);

  return (
    <Dialog
      open={showsQuotationUserMailModal}
      onClose={onQuotationUserMailModalClose}
    >
      <DialogTitle>확정견적을 수정 하시겠습니까?</DialogTitle>

      <DialogContent>
        <FormControlLabel
          control={
            <Checkbox
              checked={allowsSendMail}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAllowsSendMail(e.target.checked);
              }}
            />
          }
          label="화주에게 견적 수정사항을 안내하는 메일을 발송"
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="error"
          onClick={onQuotationUserMailModalClose}
        >
          취소
        </Button>

        <Button
          variant="contained"
          onClick={() => onQuotationUserUpdate(allowsSendMail)}
        >
          수정
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default QuotationUserMailModal;
