import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Save as SaveIcon } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import { sendRequest } from "@sellernote/_shared/src/services/request";

const AdminUserRegister = () => {
  const { handleSnackbarOpen } = useSnackbar();

  const [adminAuthority, setAdminAuthority] = useState("");
  const [adminName, setAdminName] = useState("");
  const [adminEnglishName, setAdminEnglishName] = useState("");
  const [adminPassword, setAdminPassword] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminId, setAdminId] = useState("");

  const history = useHistory();

  const createAdmin = async () => {
    if (!adminEnglishName) {
      handleSnackbarOpen("영문 이름을 입력해주세요.", "error");
      return;
    }

    try {
      await sendRequest({
        method: "post",
        path: `/auth/admins`,
        apiType: "SHIPDA_ADMIN_API",
        data: {
          accountId: adminId,
          name: adminName,
          nameEN: adminEnglishName,
          email: adminEmail,
          authority: adminAuthority,
          password: adminPassword,
        },
      });

      history.go(-1);
    } catch (err) {
      handleSnackbarOpen("요청에 실패했습니다.", "error");
    }
  };

  const authority = ["관리자", "매니저", "정산 담당자"];

  return (
    <Layout breadcrumbs={["사용자 관리"]} title={"사용자 추가"}>
      <Box sx={{ padding: "20px" }}>
        <Typography variant="subtitle1" fontWeight="bold">
          권한
        </Typography>

        <Autocomplete
          sx={{
            width: "300px",
            marginTop: "5px",
          }}
          options={authority}
          size="small"
          renderInput={(params) => <TextField {...params} label="권한" />}
          onChange={(_, value) => {
            let authorityName = "";

            if (value === "관리자") {
              authorityName = "master";
            } else if (value === "매니저") {
              authorityName = "manager";
            } else if (value === "정산 담당자") {
              authorityName = "finance";
            }

            setAdminAuthority(authorityName);
          }}
        />

        <Typography variant="subtitle1" fontWeight="bold" mt={2}>
          아이디
        </Typography>

        <TextField
          sx={{ width: "300px", marginTop: "5px" }}
          label="아이디"
          size="small"
          onChange={(e) => setAdminId(e.target.value)}
        />

        <Typography variant="subtitle1" fontWeight="bold" mt={2}>
          이름
        </Typography>

        <TextField
          sx={{ width: "300px", marginTop: "5px" }}
          label="이름"
          size="small"
          onChange={(event) => setAdminName(event.target.value)}
        />

        <Typography variant="subtitle1" fontWeight="bold" mt={2}>
          영문 이름
        </Typography>

        <TextField
          sx={{ width: "300px", marginTop: "5px" }}
          label="영문 이름"
          size="small"
          onChange={(event) => setAdminEnglishName(event.target.value)}
        />

        <Typography variant="subtitle1" fontWeight="bold" mt={2}>
          이메일
        </Typography>

        <TextField
          sx={{ width: "300px", marginTop: "5px" }}
          label="이메일"
          size="small"
          onChange={(e) => setAdminEmail(e.target.value)}
        />

        <Typography variant="subtitle1" fontWeight="bold" mt={2}>
          비밀번호
        </Typography>

        <TextField
          type="password"
          sx={{ width: "300px", marginTop: "5px" }}
          label="비밀번호"
          size="small"
          onChange={(event) => setAdminPassword(event.target.value)}
        />

        <Box>
          <Button
            onClick={createAdmin}
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ mt: 3 }}
          >
            생성
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default AdminUserRegister;
