import { Box } from "@mui/material";
import { useAtom } from "jotai";

import Layout from "@sellernote/_shared/src/admin-ui/containers/Layout";
import useShipmentTableHeadFilter from "@sellernote/_shared/src/admin-ui/pageContainers/bid/hooks/useShipmentTableHeadFilter";
import ShipmentsSearchFilter from "@sellernote/_shared/src/admin-ui/pageContainers/bid/ShipmentsSearchFilter";
import ShipmentTable from "@sellernote/_shared/src/admin-ui/pageContainers/bid/ShipmentTable";
import { FORWARDING_ADMIN_BID_JOTAI_ATOMS } from "@sellernote/_shared/src/jotaiStates/bid";
import ADMIN_BID_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import ADMIN_USER_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_USER_QUERY";

// TODO: 의뢰 리스트의 page URL을 shipment로 할지 논의 후 수정
const Bid = () => {
  const [filterData, setFilterData] = useAtom(
    FORWARDING_ADMIN_BID_JOTAI_ATOMS.SHIPMENT_TABLE_FILTER_LIST
  );

  const { ShipmentTableHeadFilter, tableHeadFilterData, filterDataReset } =
    useShipmentTableHeadFilter();

  const {
    data: bidListData,
    refetch: refetchBidListData,
    isLoading: isLoadingBidList,
  } = ADMIN_BID_QUERY.useGetAdminBidList({
    params: {
      ...filterData,
      ...tableHeadFilterData,
    },
  });

  const pagination = {
    rowsPerPageOptions: [10, 20, 50, 100, 500, 1000, 10000],
    totalCount: bidListData?.total || 0,
    perPage: filterData.perPage || 20,
    setPerPage: (perPage: number) => setFilterData({ ...filterData, perPage }),
    currentPage: filterData.page || 0,
    setCurrentPage: (page: number) => setFilterData({ ...filterData, page }),
  };

  const {
    adminUserList,
    adminDataForTableFilter,
    isLoading: isLoadingForGetAdminUserList,
  } = ADMIN_USER_QUERY.useGetAdminUserList();

  return (
    <Layout title="선적계획 의뢰">
      <Box sx={{ p: 1, pt: 1.5, background: "#fff" }}>
        <ShipmentsSearchFilter />
      </Box>

      <Box sx={{ p: 1, background: "#fff" }}>
        <ShipmentTable
          adminFilterArray={adminDataForTableFilter}
          adminData={adminUserList}
          bidList={bidListData?.list}
          TableHeadPanel={ShipmentTableHeadFilter}
          refetchBidListData={refetchBidListData}
          pagination={pagination}
          isLoadingBidList={isLoadingBidList}
          isLoadingForGetAdminUserList={isLoadingForGetAdminUserList}
          filterDataReset={filterDataReset}
        />
      </Box>
    </Layout>
  );
};

export default Bid;
