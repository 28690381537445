import { useFormContext } from "react-hook-form";
import { Grid } from "@mui/material";

import TemplateSearch from "@sellernote/_shared/src/admin-ui/containers/TemplateSearch";
import {
  GET_ADMIN_BID_APPLY_ITEMS_RES,
  GET_ADMIN_BID_DETAIL_RES,
} from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import { ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID } from "@sellernote/_shared/src/constants/forwarding/adminBid";
import {
  FreightType,
  Liner,
} from "@sellernote/_shared/src/types/common/common";
import { ApplyBidFormData } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { ExchangeRate } from "@sellernote/_shared/src/types/forwarding/trello";

import QuotationEtcFormList from "./QuotationEtcFormList";
import QuotationFeeForm from "./QuotationFeeForm";

function QuotationForm({
  bidDetail,
  freightType,
  exchangeRateList,
  applyItems,
  linerList,
  showsPreviousItemPrice,
  readonly = false,
  onSubmit,
  children,
}: {
  bidDetail?: GET_ADMIN_BID_DETAIL_RES;
  freightType: FreightType;
  exchangeRateList: ExchangeRate[];
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES;
  linerList: Liner[];
  showsPreviousItemPrice?: boolean;
  readonly?: boolean;
  onSubmit: () => void;
  children?: React.ReactNode;
}) {
  const bidId = bidDetail?.id;

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useFormContext<ApplyBidFormData>();

  return (
    <Grid container gap={1} component="form" onSubmit={handleSubmit(onSubmit)}>
      {!readonly && (
        <TemplateSearch
          bidId={bidId}
          templateCategory={"all"}
          reset={reset}
          watch={watch}
          templateFeeDataType={"all"}
          setValue={setValue}
          templateType="bidApply"
          exchangeRateList={exchangeRateList}
        />
      )}

      <QuotationFeeForm
        bidId={bidId}
        quotationFeeFormCategory={"localCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"localFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"localFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
        readonly={readonly}
      />

      <QuotationFeeForm
        bidId={bidId}
        quotationFeeFormCategory={"freightCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"freightFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"freightFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
        readonly={readonly}
      />

      <QuotationFeeForm
        bidId={bidId}
        quotationFeeFormCategory={"domesticCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"domesticFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"domesticFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
        readonly={readonly}
      />

      <QuotationFeeForm
        bidId={bidId}
        quotationFeeFormCategory={"inlandCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"inlandFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"inlandFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
        readonly={readonly}
      />

      <QuotationFeeForm
        bidId={bidId}
        quotationFeeFormCategory={"otherCost"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"otherFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"otherFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
        readonly={readonly}
      />

      <QuotationFeeForm
        bidId={bidId}
        quotationFeeFormCategory={"tax"}
        reset={reset}
        watch={watch}
        templateFeeDataType={"taxFeeData"}
        setValue={setValue}
        templateType="bidApply"
        exchangeRateList={exchangeRateList}
        applyShipmentFormFeeDataType={"taxFeeData"}
        control={control}
        applyItems={applyItems}
        bidDetail={bidDetail}
        showsPreviousItemPrice={showsPreviousItemPrice}
        readonly={readonly}
      />

      <QuotationEtcFormList
        control={control}
        errors={errors}
        linerList={linerList}
        freightType={freightType}
        showsAnotherCommentCheck={
          !!(
            bidDetail?.endViaPortId &&
            bidDetail?.zoneId &&
            // 인천, 부산, 인천신항, 부산신항만 접안항 코멘트가 존재
            ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID.some(
              (port) => port.id === bidDetail?.endViaPortId
            )
          )
        }
        readonly={readonly}
      />

      {!readonly && (
        <Grid
          item
          xs={12}
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            columnGap: "8px",
            marginTop: "32px",
          }}
        >
          {children}
        </Grid>
      )}
    </Grid>
  );
}

export default QuotationForm;
