import { Grid } from "@mui/material";
import { useRecoilValue } from "recoil";

import { FORWARDING_ADMIN_BID_CREATE_ATOMS } from "@sellernote/_shared/src/states/forwarding/adminBidCreate";
import { FreightType } from "@sellernote/_shared/src/types/common/common";
import { BidProductInfo } from "@sellernote/_shared/src/types/forwarding/bid";
import {
  calculateRTon,
  getCustomRound,
  toTon,
} from "@sellernote/_shared/src/utils/common/number";

import LCLAndAIRItemList from "@sellernote/_shared/src/admin-ui/components/CargoInfo/GeneralCargoInfo/LCLAndAIRItemList";
import LCLAndAIRTotalList from "@sellernote/_shared/src/admin-ui/components/CargoInfo/GeneralCargoInfo/LCLAndAIRTotalList";

export default function ItemListForLCLAndAIR({
  freightType,
  productsInfo,
}: {
  freightType: FreightType;
  productsInfo: BidProductInfo[];
}) {
  const cargoInfoFormType = useRecoilValue(
    FORWARDING_ADMIN_BID_CREATE_ATOMS.CARGO_INFO_FORM_TYPE
  );

  const itemGroupList = (() => {
    return productsInfo.map((item, i) => {
      return {
        item: {
          id: i + 1,
          name: item.name,
          packingType: item.packingType,
          quantity: item.quantity,
          horizontal: item.horizontal,
          vertical: item.vertical,
          height: item.height,
          volumeUnit:
            item.volumeUnit === "m"
              ? ("M" as const)
              : item.volumeUnit === "cm"
              ? ("CM" as const)
              : ("M" as const),
          weight: item.weight,
          weightUnit: item.weightUnit,
          isDangerous: item.isDangerous,
          needRefrigeration: item.needRefrigeration,
          canStack: item.isDouble,
          cbm: item.cbm,
        },
      };
    });
  })();

  const supply = (() => {
    const totalCbm = productsInfo.reduce((acc, item) => {
      return acc + item.cbm;
    }, 0);

    const totalWeightAsTon = productsInfo.reduce((acc, item) => {
      return acc + toTon(item.weight, item.weightUnit) * Number(item.quantity);
    }, 0);

    return getCustomRound(calculateRTon(totalCbm, totalWeightAsTon), 3);
  })();

  return (
    <Grid item xs={12}>
      {cargoInfoFormType === "packaging" && (
        <LCLAndAIRItemList
          itemGroupList={itemGroupList}
          supply={supply}
          freightType={freightType}
        />
      )}

      {cargoInfoFormType === "totalVolume" && (
        <LCLAndAIRTotalList
          itemGroupList={itemGroupList}
          supply={supply}
          freightType={freightType}
        />
      )}
    </Grid>
  );
}
