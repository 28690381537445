import { useMemo } from "react";
import {
  Control,
  useFieldArray,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { Button, Divider, Grid } from "@mui/material";
import { blue, grey } from "@mui/material/colors";

import { GET_ADMIN_BID_APPLY_ITEMS_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import {
  AdminBidApplyCostType,
  ApplyBidFormData,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { TemplateFeeDataType } from "@sellernote/_shared/src/types/forwarding/adminTemplate";
import {
  ExchangeRate,
  TrelloBidDetail,
} from "@sellernote/_shared/src/types/forwarding/trello";
import { getRandomNumber } from "@sellernote/_shared/src/utils/common/number";

import ApplyForm from "./ApplyForm";

function ApplyBidFormList({
  applyItemListCostType,
  control,
  applyShipmentFormFeeDataType,
  applyItems,
  exchangeRateList,
  setValue,
  bidDetail,
  showsPreviousItemPrice,
  directAirRton,
  readonly = false,
}: {
  applyItemListCostType: AdminBidApplyCostType;
  control: Control<ApplyBidFormData>;
  applyShipmentFormFeeDataType: Exclude<
    TemplateFeeDataType,
    "all" | "withdrawalFormList"
  >;
  applyItems: GET_ADMIN_BID_APPLY_ITEMS_RES | undefined;
  exchangeRateList: ExchangeRate[] | undefined;
  setValue: UseFormSetValue<ApplyBidFormData>;
  bidDetail?: TrelloBidDetail;
  showsPreviousItemPrice?: boolean;
  directAirRton?: number;
  readonly?: boolean;
}) {
  const feeFormList = useWatch({ control, name: applyShipmentFormFeeDataType });

  const { append } = useFieldArray({
    control,
    name: applyShipmentFormFeeDataType,
  });

  /** API로 주는 아이템 항목 중 항공/해상 운임 카테고리인지 확인 */
  const isFreightApplyItem = applyItemListCostType === "freightCost";

  const itemListForAutoCompleteOptions = useMemo(() => {
    if (!applyItems) {
      return [];
    }

    return applyItems
      .filter((item) => {
        return item.category === applyItemListCostType;
      })
      .map((item) => {
        return item.name;
      });
  }, [applyItems, applyItemListCostType]);

  const handleItemAdd = () => {
    const randomKey = `etc${getRandomNumber()}`;

    const duplicateKeyItem = feeFormList.find((v) => {
      return v.key === randomKey;
    });
    // TODO: 수출 시 currency의 기본값이 다양한 통화가 되어야함 근데 기준 환율을 알 수 있는 방법이 없다.
    append({
      // 중복키 항목이 있다면 key를 다시 생성
      key: duplicateKeyItem ? `etc${getRandomNumber()}` : randomKey,
      item: "",
      note: "",
      isVAT: false,
      amount: 0,
      atCost: false,
      currency: "KRW",
      itemPrice: 0,
      unitPrice: 0,
      itemUnitMeasurement: "",
      isTaxExempt: false,
    });
  };

  return (
    <Grid container>
      <Grid
        item
        container
        columns={24}
        alignItems="center"
        sx={{ backgroundColor: grey[100], height: 32 }}
      >
        <Grid item sx={{ width: 230 + (isFreightApplyItem ? 8 : 0) }}>
          항목
        </Grid>

        {!isFreightApplyItem && (
          <Grid item sx={{ width: 50 }}>
            실비
          </Grid>
        )}

        <Grid item sx={{ width: 135 }}>
          화폐
        </Grid>

        <Grid item sx={{ width: 165 }}>
          단가
        </Grid>

        <Grid item sx={{ width: 77 }}>
          수량
        </Grid>

        <Grid item sx={{ width: 125 }}>
          견적가(외화)
        </Grid>

        <Grid item sx={{ width: 187 }}>
          견적가(KRW)
        </Grid>

        <Grid item sx={{ width: 180 }}>
          견적단위
        </Grid>

        <Grid item sx={{ width: 80 }}>
          VAT 별도
        </Grid>

        <Grid item sx={{ width: 68 }}>
          비과세
        </Grid>

        <Grid item sx={{ width: 200 }}>
          비고
        </Grid>

        {!readonly && <Grid item>삭제</Grid>}
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ bgcolor: blue[700] }} component={"div"} />
      </Grid>

      <Grid item container sx={{ marginTop: "8px" }}>
        {exchangeRateList &&
          feeFormList?.map((applyFormData, index) => {
            return (
              <ApplyForm
                itemListForAutoCompleteOptions={itemListForAutoCompleteOptions}
                applyShipmentFormFeeDataType={applyShipmentFormFeeDataType}
                key={applyFormData.key}
                applyFormData={applyFormData}
                control={
                  control as Control<ApplyBidFormData, Record<string, unknown>>
                }
                formIndex={index}
                isFreightApplyItem={isFreightApplyItem}
                exchangeRateList={exchangeRateList}
                setValue={setValue}
                bidDetail={bidDetail}
                applyItems={applyItems}
                showsPreviousItemPrice={showsPreviousItemPrice}
                directAirRton={directAirRton}
                readonly={readonly}
              />
            );
          })}
      </Grid>

      {!readonly && (
        <Grid item>
          <Button variant="contained" color="success" onClick={handleItemAdd}>
            추가
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

export default ApplyBidFormList;
